
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import ChooseForgeModel from "@/components/3dAppsDialogs/ChooseForgeModel"
import ConnectToForgeViewer from "@/components/3dAppsDialogs/ConnectToForgeViewer"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useConnectorStore } from "@/stores/connector"
import { Connector } from "@evercam/shared/types"

export default {
  components: {
    CreateUploadDialog,
    ChooseForgeModel,
    ConnectToForgeViewer,
  },
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    bimUploadObject: undefined,
    bimUploadDialog: false,
    autodeskDialog: false,
    askTokenDialog: false,
  }),
  computed: {
    ...mapStores(
      useIngestFileUploaderStore,
      useProjectStore,
      useAccountStore,
      useConnectorStore
    ),
  },
  methods: {
    async generateBimUploadLink() {
      if (!this.projectStore.selectedProjectExid) {
        return
      }
      try {
        const response = await IngestApi.bim.createUpload(
          this.projectStore.selectedProjectExid,
          {
            uploadedBy: this.accountStore.email,
          }
        )

        this.bimUploadObject = {
          ...response,
          projectName: this.projectStore.selectedProject.name,
        }
        const uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
          uploadType: INGEST_UPLOADS_TYPES.bimUploads,
        }
        this.ingestFileUploaderStore.currentUploadStats = uploadStats
        this.ingestFileUploaderStore.uploadStats.push(uploadStats)
        this.bimUploadDialog = true
      } catch (error) {
        this.$notifications.error({
          text: "Could not get token ",
          error,
        })
      }
    },
    onBimUplaodDialogClosed() {
      this.ingestFileUploaderStore.currentBIMUploadStat = undefined
      this.bimUploadDialog = false
      this.bimUploadObject = undefined
    },
    async selectModel(file) {
      let token
      try {
        token = await EvercamApi.autodesk.fetchViewerToken(
          this.projectStore.selectedProject.exid
        )
      } catch (_e) {
        console.log("Token not implemented")
      }
      if (!token) {
        this.askTokenDialog = true

        return
      }
      this.autodeskDialog = false
      this.connectorStore.setForgeModelId(
        this.projectStore.selectedProject.exid,
        file
      )
    },
    initAutodesk() {
      if (this.connectorStore.isAutodeskConnected) {
        this.autodeskDialog = true
      } else {
        this.connectorStore.openConnectorPromptDialog(Connector.Autodesk)
      }
    },
  },
}
